import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import Data from "../ReportResponse/data.json";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { AuthContext } from "../../../contexts/AuthContext";
import ErrorIcon from "../../../assets/Nofile.png";
import { get } from "../../../Utils/Function/Method";
import { useParams } from "react-router-dom";


function Index() {
  const { surveyId } = useParams();
  const surveyInt = parseInt(surveyId);
  // console.log("IDDDDDDd",surveyInt)
  const [surveyResponse, setSurveyResponse] = useState([]);
  async function fetchData() {
    try {
      const response = await get(`/response_info/${surveyInt}`);
      // console.log("response", response[1][0]);
      // console.log("responseeeee",response)
      setSurveyResponse(response[1][0]);
      // console.log(")))))))))))))))))))",surveyResponse)
    
    } catch (error) {
      console.log("fetch error ", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  // console.log("Survey response",surveyResponse)

 

  return (
    <>
      <div className="report-response-table">
        {surveyResponse?.length > 0 ? (
          <table>
          <thead>
            <tr>
              {surveyResponse?.map((question,index) => (
                <th key={question.q_id} className="ellipsis-cell">{question.q_title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
            {surveyResponse.map((question,index) => (
        <td key={index}  >
          {question.answers.map((answer, index) => (
           <div key={index} className="answer-item">
            <span className="q-answer-text">
          {answer.q_answer !== null && answer.q_answer !== "" ? answer.q_answer : "null"}
        </span>
                 {/* <span className="q-answer-text">{answer.q_answer}</span> */}
              {/* {answer.q_answer} */}
              {index < question.answers.length - 1 }
                {/* {index > 0 && <br />} */}
            </div> 
          ))}
        </td>
      ))}
             
            </tr>
          </tbody>
        </table>
          
          
        
       
        ) : (
          <div className="report-no-data">
            <div className="report-no-data-image">
              <img src={ErrorIcon} alt="filenotfound" />
            </div>
            <div className="report-no-data-header">
              <p> You haven't created any questions.</p>
              <p>Go Back to start buillding your survey or you dont receive any responses.</p>
            </div>
            <div className="report-no-data-button">
              {/* 
              <button  onClick={()=>navigate('/edit-survey')} >Create Survey</button> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default Index;
